import React, { useState } from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import loadable from '@loadable/component';

import useShiftTime from '@hooks/useShiftTime';
import useInvitation from '@hooks/useInvitation';
import useIntersect from '@jackyef/use-intersect';
import FilterSection from '@/components/Section/Filter';

import WithAnimation from '@components/Common/WithAnimation';

import { COVER_GALLERY_1 } from '@/constants/assets';
import { ENABLE_IG_FILTER, ENABLE_QUOTES } from '@/constants/features-flag';

const LazyGallery = loadable(() => import(/* webpackChunkName: "lazy-gallery" */ './Gallery'));

const optionsData = {
  root: null,
  rootMargin: '0px',
  threshold: [0, 0, 0, 0],
};

/**
 * function to render Photo Slider
 * This component using `react-image-gallery`
 * @returns {JSX.Element}
 */
function PhotoSliderSection({ ...rest }) {
  const [loaded, setLoaded] = useState(false);

  const IsInvitation = useInvitation();
  const onIntersect = () => {
    setLoaded(true);
  };
  const time = useShiftTime();

  const targetRef = useIntersect(onIntersect, optionsData, true);

  return (
    <Box bgColor="white" padding="32px 16px" {...rest}>
      <Box p="32px" paddingBottom="0" bgColor="mainColor" borderRadius="16px">
        {ENABLE_QUOTES && <WithAnimation>
          <Center>
            <Text textAlign="center" marginBottom="16px" color="white" fontSize="lg">
            <i>“When we fall in love, it will be forever”</i>
            </Text>
          </Center>
        </WithAnimation>}
        <WithAnimation>
          <Image src={COVER_GALLERY_1} borderRadius="16px" marginBottom="16px" />
        </WithAnimation>
        <WithAnimation>
          <Box ref={targetRef}>{loaded ? <LazyGallery /> : 'Loading gallery...'}</Box>
        </WithAnimation>
        <Box height="32px" />
      </Box>
      {ENABLE_IG_FILTER && IsInvitation && time !="Tasyakuran" &&
          <FilterSection />
      }
    </Box>
  );
}

export default React.memo(PhotoSliderSection);
